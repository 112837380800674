<template>
  <section class="is--slider is--slider-main hidden-xs" v-if="getBanners.length">
    <ssr-carousel
      gutter="0"
      loop="true"
      show-dots="true"
      show-arrows="true"
      :autoplay-delay="5"
    >
      <div class="slide" v-for="(banner, index) in getBanners" :key="index">
        <div class="carousel">
          <div class="carousel-item">
            <div class="carousel-item-image">
              <img
                v-if="index > 0"
                v-lazy="getThumbnail(banner.image,1380,400,'banner')"
                :alt="banner.name"
              >
              <img
                v-if="index == 0"
                :src="getThumbnail(banner.image,1380,400,'banner')"
                :alt="banner.name"
              >
            </div>
            <div class="carousel-item-inner">
              <div
                class="carousel-item-subhead"
                v-if="banner.image_alt"
              >
                {{ banner.image_alt }}
              </div>
              <div class="carousel-item-head">
                {{ banner.name }}
              </div>
              <div class="carousel-item-content" v-html="banner.caption" />
              <a :href="banner.click_url" class="btn btn-primary">{{ banner.button_text || "Jetzt ansehen" }}</a>
            </div>
          </div>
        </div>
      </div>
    </ssr-carousel>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'

import SsrCarousel from 'vue-ssr-carousel'
import ssrCarouselCss from 'vue-ssr-carousel/index.css'

export default {
  props: {
    id: {
      type: [Number, String],
      required: true
    }
  },
  computed: {
    ...mapGetters({
      getBannersBySlider: 'bannerslider/getBannersBySlider'
    }),
    getBanners () {
      return this.getBannersBySlider(this.id).sort((a, b) => a.order_banner - b.order_banner)
    },
    getMainBannerImage () {
      const banner = this.getBanners.find((b) => true);
      const image = (banner) ? banner.image : null
      return this.getThumbnail(image, 1380, 400, 'banner')
    }
  },
  components: {
    'ssr-carousel': SsrCarousel
  },
  methods: {
    carouselNav ($event, direction) {
      const ref = $event.target.closest('[data-ref]').dataset.ref;
      const carousel = this.$refs[ref];

      carousel.advancePage(direction);
    }
  },
  metaInfo () {
    return {
      link: [
        { rel: 'preload', href: this.getMainBannerImage, as: 'image' }
      ]
    }
  }
}
</script>
