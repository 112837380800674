<template>
  <section
    :class="(categories.length > 2) ? 'is--slider is--slider-categories' : 'is--slider-categories-top'"
  >
    <div class="container">
      <h2 class="section-head" v-if="heading">
        {{ heading }}
      </h2>

      <div
        class="categories-box-wrap"
        :class="[{ 'categories-box-grid' : isGrid }, (columns === 3) ? 'categories-box-grid__3' : 'categories-box-grid__4']"
      >
        <router-link
          class="categories-box"
          :to="categoryLink(category)"
          :key="category.id"
          v-for="category in loadedCategories"
        >
          <div class="categories-box-heading" v-if="categories.length <= 2">
            <div>{{ category.name }}</div>
          </div>

          <div class="categories-box-content">
            <h3>{{ category.name }}</h3>
            <span class="more">{{ category.zusatzzeile }}</span>
            <div class="transition-wrapper">
              <p>{{ category.category_linktext || category.name }}</p>
              <span class="btn btn-primary">
                <span :class="(categories.length > 2) ? 'hidden-xs' : ''">Artikel ansehen</span>
                <span class="visible-xs" v-if="(categories.length > 2)">Ansehen</span>
              </span>
            </div>
          </div>

          <div class="categories-box-image">
            <div class="box-badge-offer" v-if="category.sale_label">
              {{ category.sale_label }}
            </div>
            <img
              v-if="preload !== category.id"
              v-lazy="getThumbnail(category.image,600,600,'category')"
              :data-srcset="getThumbnail(category.image,700,700,'category') + ' 600w,' + getThumbnail(category.image,600,600,'category') + ' 500w,' + getThumbnail(category.image,500,500,'category') + ' 400w,' + getThumbnail(category.image,400,400,'category') + ' 300w'"
              :alt="category.name"
            >
            <img
              v-if="preload === category.id"
              :src="getThumbnail(category.image,600,600,'category')"
              :alt="category.name"
            >
          </div>
        </router-link>

        <div
          class="categories-extra-tile"
          v-if="categories.length % columns !== 0 && categories.length > 2 && showExtraTile"
        >
          <h5>Nichts passendes dabei? Wir beraten kostenlos & kompetent:</h5>
          <a :href="'tel:' + storeConfig('general/store_information/phone')" target="_blank">{{ phoneNumber }}</a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'

import { formatCategoryLink } from '@vue-storefront/core/modules/url/helpers'

export default {
  data () {
    return {
      secondLevelCategories: this.$store.state['category-next'].menuCategories.filter(c => { return c.level === 2 })
        .sort((a, b) => a.position - b.position)
    }
  },
  props: {
    categories: {
      type: null,
      required: true
    },
    heading: {
      type: [String],
      required: false
    },
    className: {
      type: [String],
      required: false
    },
    isGrid: {
      type: [Boolean],
      required: false
    },
    columns: {
      type: [Number, String],
      required: false
    },
    showExtraTile: {
      type: [Boolean],
      required: false
    },
    sortByProp: {
      type: [Boolean],
      required: false,
      default: false
    },
    preload: {
      type: [Boolean],
      required: false,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      storeConfig: 'storeConfig/getConfigValue'
    }),
    loadedCategories () {
      let categories = this.categories;
      if (typeof this.categories[0] === 'object') {
        categories = categories.map(a => a.id)
      }
      const filteredCategories = this.filterMenuCategories(categories);

      if (this.sortByProp) {
        return filteredCategories.sort((a, b) => {
          return this.categories.indexOf(a.id) - this.categories.indexOf(b.id)
        });
      }

      return filteredCategories
    },
    phoneNumber () {
      const phone = this.storeConfig('general/store_information/phone')
      return typeof phone === 'string' ? phone.replace(/^02/, '+49 (0) 2') : ''
    }
  },
  mounted () {
    this.loadCategories(this.categories);
  },
  watch: {
    categories: async function (categories, oldVal) {
      await this.loadCategories(categories);
    }
  },
  methods: {
    async loadCategories (categories) {
      if (typeof categories[0] === 'object') {
        categories = categories.map(a => a.id)
      }
      if (this.filterMenuCategories(categories).length !== categories.length) {
        await this.$store.dispatch('category-next/fetchMenuCategories', { key: 'id', value: categories })
      }
    },
    categoryLink (category) {
      return formatCategoryLink(category)
    },
    filterMenuCategories (categories) {
      return this.$store.state['category-next'].menuCategories.filter(category => {
        return categories.includes(category.id)
      })
    }
  },
  metaInfo () {
    const category = this.loadedCategories.find(c => c.id === this.preload)
    if (category) {
      return {
        link: [
          { rel: 'preload', href: this.getThumbnail(category.image, 600, 600, 'category') }
        ]
      }
    }
  }
}
</script>
